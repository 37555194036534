import React from 'react';

import Layout from '../../../../components/MenuLayout';
import { OpenStowRsAdd } from '../../../../components/Hospital/Management/OpenStowRs/OpenStowRsAdd';

export default () => (
  <Layout type="settings">
    <OpenStowRsAdd />
  </Layout>
);
