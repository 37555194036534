import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';

import { AddBox, NavigateBefore } from '@material-ui/icons';
import { Box, Paper } from '@material-ui/core';

import { Navbar } from '../../../Navbar/styled/NavbarStyles';
import { SectionBar } from '../../../../componentsUI/SectionBar';
import { ScrollableContainer } from '../../../Common/ScrollableContainer';
import useStyles from '../../Edit/styled/HospitalFormMakeStyles';
import { OpenStowRsForm } from './OpenStowRsForm';
import { GET_HOSPITALS } from '../../../../graphql/queries';
import { parseGraphqlErrors } from '../../../../utils/FormikUtils';
import { networkErrorParse } from '../../../../utils/ErrorGraphQLUtils';
import { CREATE_OPEN_STOW_RS_ACCESS } from '../../../../queries/OpenStowRs/OpenStowRs';

export const OpenStowRsAdd = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const hospital = useSelector((state) => state.hospital);
  const [serverError, setServerError] = useState(null);
  const [lockSubmit, setLockSubmit] = useState(false);
  const form = useRef();

  const updateErrorAction = (updateError) => {
    if (!updateError) {
      return;
    }
    if (updateError.includes('|')) {
      const [message, uuid] = updateError.split('|');
      setServerError({ level: 'info', message });
      setLockSubmit(true);
      toast(`${t('redirecting.to.existing.reference')}...`, { className: 'toast-warning' });
      setTimeout(() => navigate(`/hospital/detail/open-stow-rs/${uuid}`), 5000);
      return;
    }
    setLockSubmit(false);
    setServerError({ level: 'error', message: updateError });
  };

  const [createOpenStowRs, { loading: submitting }] = useMutation(CREATE_OPEN_STOW_RS_ACCESS, {
    onCompleted: async ({ createOpenStowRsAccess }) => {
      toast(`${t('link.created.successfully')}...`, { className: 'toast-info' });
      setTimeout(() => navigate(`/hospital/detail/open-stow-rs/${createOpenStowRsAccess.uuid}`), 3500);
    },
    onError: (e) => {
      const errorMsg = networkErrorParse(e).replace(/:/g, '.');
      updateErrorAction(errorMsg);
    },
    refetchQueries: [{ query: GET_HOSPITALS }],
    awaitRefetchQueries: true,
  });

  const handleCancel = () => navigate('/hospital/settings/open-stow-rs');
  const requestSubmit = () => form.current.submitForm();
  const handleSubmit = async (values, { setErrors }) => {
    setServerError(null);
    setLockSubmit(true);
    try {
      const input = {
        userUuid: values.manager,
        hospitalUuid: hospital.uuid,
        ...values.teamMember ? { teamMemberUuid: values.teamMember } : {},
        ...values.userGroup ? { userGroupUuid: values.userGroup } : {},
        ...values.ipAddress ? { ipAddress: values.ipAddress } : {},
      };
      await createOpenStowRs({ variables: { input } });
    } catch (errors) {
      const formikErrors = parseGraphqlErrors(errors.graphQLErrors, t);
      if (Object.keys(formikErrors).length === 0 && formikErrors.constructor === Object) {
        setServerError({ level: 'error', message: networkErrorParse(errors) });
      } else {
        setErrors(formikErrors);
      }
    }
  };

  const addLabel = 'generate.new.access';
  const buttons = [
    { name: 'go.back.to.list', icon: NavigateBefore, handleClick: handleCancel, disabled: false },
    { name: 'divider2', type: 'divider' },
    { name: addLabel, icon: AddBox, I: 'delete', data: hospital, handleClick: requestSubmit },
  ];

  return (
    <>
      <Navbar>
        <SectionBar
          title="hospital.settings.open.stow.rs"
          subtitle={t('hospital.settings.open.stow.rs.add.new')}
          items={buttons}
        />
      </Navbar>
      <Box className={classes.container}>
        <ScrollableContainer padding="1.25em 0 0">
          <Paper elevation={2} className={classes.wrapper}>
            {!!hospital && (
              <OpenStowRsForm
                form={form}
                hospital={hospital}
                submitLabel={addLabel}
                handleSubmit={handleSubmit}
                serverError={serverError}
                lockSubmit={lockSubmit}
                submitting={submitting}
              />
            )}
          </Paper>
        </ScrollableContainer>
      </Box>
    </>
  );
};
