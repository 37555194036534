import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { DialogActions, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { Form } from '../../../Common/styled/Form';
import { AlertContainerUI, AlertUI } from '../../../../componentsUI/Alert';
import { ButtonUI } from '../../../../componentsUI/Button';
import { canI } from '../../../../casl/Can';
import Loading from '../../../Common/Loading';
import { TextFieldSelectUI } from '../../../../componentsUI/TextFieldSelect';
import { capitalize } from '../../../../utils/StringUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  main: {
    marginBottom: 20,
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
    border: `1px solid ${theme.palette.primary.main}30`,
  },
  fieldset: {
    border: 0,
    margin: 0,
    padding: 0,
    '& .MuiGrid-container .MuiGrid-grid-xs-12:last-child': {
      height: 24,
      marginBottom: 16,
    },
  },
  option: {
    '& .MuiFormControlLabel-label': {
      fontSize: '1.3rem',
      lineHeight: 1.15,
    },
  },
}));

export const OpenStowRsForm = ({
  hospital,
  form,
  submitLabel,
  handleSubmit,
  serverError = null,
  submitting,
  lockSubmit = false,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const user = useSelector((state) => state.userInterface.user);
  const [endpointType, setEndpointType] = useState('private');
  const error = serverError && serverError.message;

  const initialValues = {
    manager: user.uuid,
    teamMember: '',
    userGroup: '',
  };

  const memberTemplate = (member, index) => ({
    id: index + 1,
    value: member.uuid,
    label: member.user && `${member.user.name} ${member.user.surname}`,
  });
  const memberOptions = hospital && hospital.teamMembers.map(memberTemplate);
  const selectMemberOptions = endpointType === 'teamMember' ? memberOptions : [];

  const groupTemplate = (group, index) => ({
    id: index + 1,
    value: group.uuid,
    label: group.name,
  });
  const groupOptions = hospital && hospital.userGroups.map(groupTemplate);
  const selectGroupOptions = endpointType === 'userGroup' ? groupOptions : [];

  const managerList = [
    { id: 1, value: user.uuid, label: `${user.name} ${user.surname}` },
  ];

  const teamMemberList = [
    { id: 0, value: initialValues.teamMember, label: t('select.team.member') },
    ...selectMemberOptions,
  ];

  const userGroupList = [
    { id: 0, value: initialValues.userGroup, label: t('select.user.group') },
    ...selectGroupOptions,
  ];

  const handleChange = (event, value) => {
    setEndpointType(event.target.value);
    if (value === 'teamMember') {
      form.current.setFieldValue('userGroup', '');
      return;
    }
    if (value === 'userGroup') {
      form.current.setFieldValue('teamMember', '');
      return;
    }
    form.current.setFieldValue('teamMember', '');
    form.current.setFieldValue('userGroup', '');
  };

  const validationSchema = (() => {
    switch (endpointType) {
      case 'teamMember':
        return Yup.object().shape({
          teamMember: Yup.string().required(t('required.field')),
          userGroup: Yup.string(),
        });
      case 'userGroup':
        return Yup.object().shape({
          teamMember: Yup.string(),
          userGroup: Yup.string().required(t('required.field')),
        });
      case 'private':
      default:
        return Yup.object().shape({
          teamMember: Yup.string(),
          userGroup: Yup.string(),
        });
    }
  })();

  const handleCancel = () => navigate('/hospital/settings/open-stow-rs');
  const canEdit = hospital ? canI('edit', hospital) : true;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      ref={form}
    >
      {(props) => (
        <Form autoComplete="off" id="addOpenStowRs">
          <fieldset className={classes.fieldset} disabled={submitting}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.main}>
                {serverError && (
                  <Grid item xs={12}>
                    <AlertUI severity={serverError.level} title={capitalize(serverError.level)}>
                      {t(error)}
                    </AlertUI>
                  </Grid>
                )}
                {!canEdit && (
                  <AlertContainerUI>
                    <AlertUI severity="warning" title={t('unauthorized')}>
                      {t('you.are.not.allowed.to.perform.this.action')}
                    </AlertUI>
                  </AlertContainerUI>
                )}
                <TextFieldSelectUI
                  name="manager"
                  label={t('manager')}
                  props={props}
                  classes={classes}
                  options={managerList}
                  readonly
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset" className={classes.option}>
                  <FormLabel component="legend">{t('select.an.endpoint.type')}</FormLabel>
                  <RadioGroup aria-label="type" name="type" value={endpointType} onChange={handleChange}>
                    <FormControlLabel
                      value="private"
                      control={<Radio color="primary" />}
                      label={t('private.access.endpoint')}
                    />
                    <FormControlLabel
                      value="teamMember"
                      control={<Radio color="primary" />}
                      label={t('shared.with.the.team.member')}
                      disabled={!memberOptions.length}
                    />
                    <FormControlLabel
                      value="userGroup"
                      control={<Radio color="primary" />}
                      label={t('shared.with.the.user.group')}
                      disabled={!groupOptions.length}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextFieldSelectUI
                  name="teamMember"
                  label={t('team.member')}
                  props={props}
                  classes={classes}
                  options={teamMemberList}
                  disabled={endpointType !== 'teamMember'}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldSelectUI
                  name="userGroup"
                  label={t('user.group')}
                  props={props}
                  classes={classes}
                  options={userGroupList}
                  disabled={endpointType !== 'userGroup'}
                />
              </Grid>
              <Grid item xs={12}>
                {submitting && <Loading />}
              </Grid>
            </Grid>

            <DialogActions>
              <ButtonUI
                variant="outlined"
                disabled={submitting || lockSubmit}
                color="primary"
                onClick={handleCancel}
              >
                {t('go.back.to.list')}
              </ButtonUI>
              <ButtonUI
                variant="contained"
                disabled={submitting || lockSubmit}
                color="primary"
                type="submit"
              >
                {t(submitLabel)}
              </ButtonUI>
            </DialogActions>
          </fieldset>
        </Form>
      )}
    </Formik>
  );
};
